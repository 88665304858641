import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Typography, Grid, CardActionArea, Card, CardMedia } from '@mui/material';
import { Box, Container } from '@mui/system';

import { OxariTimeline } from '../components/timeline';
import { OxariTeam } from '../components/team';

export class AboutPage extends React.Component {
	render() {
		return (
			<Box>
				<Helmet key='about'>
					<meta
						charSet='utf-8'
						name='description'
						content='Unlocking Sustainable Investments: Vision, Mission, and Team at Oxari: Welcome to Oxari, where we revolutionize sustainable investments through cutting-edge technology and data-driven solutions. Our vision is to create a platform that empowers investors to make informed decisions while maximizing positive environmental impact. Our mission is to provide high-quality, affordable, and accessible data to drive real-world change. With a team of dedicated professionals blending expertise in software engineering, economic modeling, and climate analysis, we are committed to reshaping financial markets for a greener and more sustainable future. Join us on this transformative journey as we unlock the full potential of sustainable investing.'
					/>
					<title>Oxari Team</title>
				</Helmet>
				<Container>
					<Box paddingTop={6} paddingBottom={3}>
						<Typography variant='pageHeader'>About us</Typography>
					</Box>

					<Box paddingBottom={3}>
						<Typography variant='h4' align='justify'>
							We are an interdisciplinary team of experts in processing, analyzing and understanding
							data related to sustainability. Oxari provides services to companies and NGOs who
							needs data to guide investment and policy for the benefit of the planet. Our
							international team brings together expertise in finance, economics, business, computer
							science, artificial intelligence, and sustainability.
						</Typography>
					</Box>

					<Grid container justifyContent='center'>
						<Grid
							item
							xs={12}
							md={6}
							sx={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							<Card
								sx={{
									width: 560,
									height: 315,
									margin: 5,
								}}
							>
								<CardActionArea>
									<CardMedia
										component='iframe'
										width='560'
										height='315'
										src='https://www.youtube.com/embed/aEAhsFDYA1E'
										title='YouTube video player'
										frameBorder='0'
										allow='accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
										allowFullScreen
									/>
								</CardActionArea>
							</Card>
						</Grid>
					</Grid>
				</Container>
				<OxariTimeline />
				<OxariTeam />
			</Box>
		);
	}
}
