export const subscriptions = [
	{
		name: 'free',
		price: 0,
		period: 'Billed at the end of every month',
		type: 'free',

		annual_credits: 100,
		api_cap: '0%',
		companies: '16',
		commercial_usage: false,
		reports: '0',
		historical_data: false,
	},
	// {
	// 	name: 'starter',
	// 	price: 2000,
	// 	period: 'Billed at the end of every month',
	// 	type: 'starter',
	//
	// 	annual_credits: 30000,
	// 	api_cap: '2%',
	// 	companies: '2,787',
	// 	commercial_usage: false,
	// 	reports: '0',
	// 	historical_data: false,
	// 	price_id: 'price_1MFJthEKExZVaAzdxHTKGpSL',
	// },
	// {
	// 	name: 'grow',
	// 	price: 5000,
	// 	period: 'Billed at the end of every month',
	// 	type: 'grow',
	// 	annual_credits: 120000,
	// 	api_cap: '12%',
	// 	companies: '17,370',
	// 	commercial_usage: false,
	// 	reports: '50',
	// 	historical_data: false,
	// 	price_id: 'price_1MFJthEKExZVaAzdxHTKGpSL',
	// },
	// {
	// 	name: 'pro',
	// 	price: 9000,
	// 	period: 'Billed at the end of every month',
	// 	type: 'pro',
	// 	annual_credits: 300000,
	// 	api_cap: '38%',
	// 	companies: '56,259',
	// 	commercial_usage: false,
	// 	reports: '150',
	// 	historical_data: false,
	// },
	// {
	// 	name: 'full',
	// 	price: 15000,
	// 	period: 'Billed at the end of every month',
	// 	type: 'full_license',
	// 	annual_credits: 600000,
	// 	api_cap: '100%',
	// 	companies: 'All',
	// 	commercial_usage: true,
	// 	reports: 'All',
	// 	historical_data: true,
	// }
];

export const subscription_definitions = [
	{
		name: 'Dashboard Costs',
		// explanation: 'Financial Data costs 1 credit, Temperature Score costs 2 credits and Scope Data costs 3 credits.',
		explanation: 'Flat rate: 20 credits per company accesed.',
	},
	{
		name: 'Annual Credits',
		explanation:
			'On renewal date, same amount of credits will be added to your balance every year. You do not lose the unconsumed credits.',
	},
	{
		name: 'Accessible Companies Pool',
		explanation:
			'Every company is priced the same. The number of companies that can be accessed is solely determined by your budget.',
	},
	{
		name: 'Commercial Usage',
		explanation: 'Please refer to contact@oxari.io.',
	},
	{
		name: 'Financial Data',
		explanation:
			'This includes the latest company data regarding: balance sheet, income & cash flow statement, fundamentals, etc.',
	},
	{
		name: 'Historical Carbon Data',
		explanation:
			'Access to carbon data over time. Analyze trends and explore the ability of companies to decouple.',
	},
	{
		name: 'Benchmarking',
		explanation:
			'Gain insights into a company’s carbon performance by comparing it with its peers through engaging and informative visualizations.',
	},
];
