import rocket from '../../imgs/rocket.webp';
import dottedLine from '../../imgs/dotted_line.webp';
import gearsMlDesc from '../../imgs/gears_ml_desc.webp';

export const servicePitchPts = [
	{ icon: rocket, description: 'Fast & secure access to carbon data with the Oxari API.' },
	{
		icon: dottedLine,
		description: 'Precise carbon predictions for companies around the globe.',
	},
	{
		icon: gearsMlDesc,
		description: 'Intuitive carbon intelligence dashboards.',
	},
];

export const oxVisions = [
	{
		title: 'Conviction',
		text: 'Green investments play a pivotal role in achieving global climate neutrality.',
	},
	{
		title: 'Mission',
		text: 'Help stakeholders drive positive change by identifying sustainable companies alongside green investment decisions paths with the help of artificial intelligence.',
	},
];

export const testimonials = [
	{
		author: 'Asha Mehta, CIO of Global Delta Capital, Author of Power of Capital (2023)',
		text: 'Oxari is a unique innovator in the ESG data industry. Their machine learning-based approach effectively fills data gaps, and their tool is simple and flexible to use. The Oxari system’s precise, AI-driven predictions are valuable for our work.',
		image:
			'https://uploads-ssl.webflow.com/6014a1bfd2d9f016dd962991/6014a1bfd2d9f000459629a7_GDC%20Logo22.svg',
		alt: 'coltura',
		width: '150px',
		height: '100px',
	},
	{
		author: 'Matthew Metz, CEO of Coltura',
		text: "I have had the pleasure of working with Oxari for more than two years and have been impressed by the team's ability to extract important insights from data as well as from the professionalism, accuracy, and deep specialized knowledge of the individual team members.",
		image: 'https://coltura.org/wp-content/uploads/2023/01/Logo_Cuadrado.png',
		alt: 'global-delta-capital',
		width: '100px',
		height: '100px',
	},
];
