import { Helmet } from 'react-helmet-async';
import { Container, Box, ListItem, List, Paper, Grid, Typography } from '@mui/material';
import { subscriptions, subscription_definitions } from '../misc/text/subscription';
import PricingCard, { CustomPricingCard } from '../components/pricing_card';

export default function PricingPage() {
	return (
		<Box>
			<Helmet key='pricing'>
				<meta
					charSet='utf-8'
					name='description'
					content='Oxari offers different plans at different pricepoints. Plans include carbon emission information of a number of companies, insights into financial data and historical data, and more. Choose the plan that best suits you and your team.'
				/>
				<title>Oxari Plans</title>
			</Helmet>
			<Container fixed>
				<Box paddingTop={6} paddingBottom={3}>
					<Typography variant='pageHeader'>Choose the right plan</Typography>
				</Box>

				<Box paddingBottom={6} width={{ md: '70%', xs: '100%' }}>
					<Typography variant='h4'>
						The plans allow you to access the data through our dashboard or API using a credit-based
						system.
					</Typography>
				</Box>

				<Grid
					container
					justifycontent='space-around'
					alignItems='stretch'
					spacing={5}
					sx={{ marginBottom: 4 }}
				>
					<Grid xs={12} sm={4} md={4} marginTop={5}>
						{subscriptions.map((subscription) => (
							<PricingCard key={subscription.name} subscription={subscription} />
						))}
					</Grid>
					<Grid xs={12} sm={4} md={4} marginTop={5}>
						<CustomPricingCard subscription={{ name: 'custom', type: 'custom' }} />
					</Grid>
				</Grid>
			</Container>

			<Box
				variant='dashed'
				sx={{
					pb: '4rem',
					bgcolor: 'background.plain',
					pt: '3rem',
					width: '100%',
				}}
			>
				<Container fixed>
					<Grid container spacing={2}>
						{subscription_definitions.map((subscription_definition) => (
							<Grid item key={subscription_definition.name} xs={12} sm={6} md={4} xl={3}>
								<Paper square sx={{ p: 3, height: '100%' }}>
									<Typography color='primary.bright_green' variant='h4'>
										{subscription_definition.name}
									</Typography>
									{subscription_definition.bulletin && (
										<List disablePadding={true} sx={{ listStyleType: 'disc' }}>
											<ListItem
												disablePadding={true}
												sx={{ display: 'list-item', marginLeft: '1em' }}
											>
												{subscription_definition.item}
											</ListItem>
											<ListItem
												disablePadding={true}
												sx={{ display: 'list-item', marginLeft: '1em' }}
											>
												{subscription_definition.item2}
											</ListItem>
											<ListItem
												disablePadding={true}
												sx={{ display: 'list-item', marginLeft: '1em' }}
											>
												{subscription_definition.item3}
											</ListItem>
										</List>
									)}
									{subscription_definition.explanation}
								</Paper>
							</Grid>
						))}
					</Grid>
				</Container>
			</Box>
		</Box>
	);
}
