import ForestIcon from '@mui/icons-material/Forest';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import DeviceThermostatIcon from '@mui/icons-material/DeviceThermostat';

export const products = [
	{
		id: '0',
		title: 'Corporate Carbon Estimation',
		icon: ForestIcon,
		list: [
			"estimate your or other companies' carbon emissions in the past?",
			'fill holes in your scope 1-3 emission data?',
			'evaluate how much your current investment portfolio produces carbon emissions?',
			'find frontrunners & laggards in reducing harmful emissions to the world?',
			'evaluate decoupling?',
		],
		paragraphs: [
			"The Oxari corporate carbon estimation tool enables access to reported & predicted emission data for over 50k+ companies. You can access the data either via our API or the intuitive dashboard. However, the tool does not only provide data, but as first of it's kind enables precise estimates of emissions caused by firms taking country, industry and company level data as input. Consequently, our tool can estimate a company's emissions, eliminating the need for direct reporting or pre-existing data in our database.",
			'Through our artificial intelligence and machine learning techniques, we can estimate and predict emissions, past and/or future, of any company of your choice. You can even use your own data as input for the predictions. Fine-tuned AI models are trained to consider several economic, financial and political factors and generate emission data, constantly minimising the error of the predictions.',
		],
		extra: '',
	},
	{
		id: '1',
		title: 'Temperature Scoring',
		icon: DeviceThermostatIcon,
		list: [
			'examine the climate compatibility of companies and build climate neutral investment portfolios?',
			'consider several policy and technology scenarios?',
			'investigate your own climate compatibility to get a fast and reliable second opinion?',
			'estimate whether you successfully separated your financial success from the expulsion of emissions?',
		],
		paragraphs: [
			'The Oxari Temperature Score (OTS) shows the global temperature the planet would reach by 2100 if a company would continue performing as before. The OTS matches historic average carbon emission growth (Scopes 1 and 2, and in some cases Scope 3) with climate scenarios well established in the scientific community (IPCC reports).',
			'Because it is impossible to foresee what the socio-economic and political landscape of the future holds, the OTS enables the user to choose between 9 scenarios that are based on different policy (Carbon Price) and technology (Carbon Removal) pathways.',
			'We combine established climate research, AI and customizable climate & economic outlooks with the most advanced technology in the market to estimate your personal temperature score. Oxari Emissions Database contains estimates for over 50,000 companies around the globe, so we are able to provide the Temperature Score for all of them.',
		],
		extra:
			'Deriving from the OTS, we also provide a Decoupling Score, which shows the relation between their revenue and their environmental footprint. If a company is increasing the first one and decreasing the second, they are decoupling and sustainably performing.',
	},
	{
		id: '2',
		title: 'Custom Climate, Economic & ESG Dashboards',
		icon: SpaceDashboardIcon,
		list: [
			'track your own sustainability performance in an all encompassing data-driven dashboard?',
			'present and visualise your sustainability insights to customers and clients?',
		],
		paragraphs: [
			'As sustainability related issues are getting increasing traction in business, policy and customer behaviour, the importance of presenting ESG performance and data analysis in an outstanding way is more important than ever.',
			'Accessible, clear and appealing maps, graphs and interactive tools increases interest and improves client-customer connection. Our experts will develop a customised dashboard according to your needs for any occasion and use-case.',
			'Below you can find one example dashboard we have developed for our clients.',
		],
		extra: '',
	},
	{
		id: '3',
		title: 'From ESG Data to Knowledge and Beyond​ with Athena',
		icon: AutoAwesomeIcon,
		list: [
			'have access to a comprehensive ESG database, built from extracting thousands of data points from multiple sources like reports, websites and more​?',
			'quary any ESG-related information using natural language?',
			'understand the ESG strategies of any company and how they relate to competitors?',
			'create a visual coprehensive analysis backed by real data in minutes?',
		],
		paragraphs: [
			'The ESG Knowledge platform comprises three distinct yet tightly integrated solutions: Athena DB, Athena AI, and Athena Custom Integrations -- that will cover every ESG need.​',
			'Athena aims to be the largest, and cheapest ESG data, knowledge and analytics platform on the market. Athena is currently in development, with a working prototype expected to be available soon. To learn more about what Athena can do, check out our video.',
		],
		extra: '',
	},
];
