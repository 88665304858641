import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';

import PostTemplete from '../components/post_templete';
import { showAuthors } from '../misc/utils';

const BLOG_URL = process.env.REACT_APP_STRAPI_BLOG_URL || 'http://localhost:1337/api';

export function PostPage() {
	let { slug } = useParams();
	const postId = slug.split('-')[0];
	const [post, setPost] = useState({});

	useEffect(() => {
		getPost(postId);
	}, [postId]);

	async function getPost(id) {
		try {
			const response = await fetch(`${BLOG_URL}/posts/${id}`);
			const { data } = await response.json();
			setPost(data);
		} catch (err) {
			console.error(err);
		}
	}

	return (
		<div className='my-5'>
			{post.id && (
				<PostTemplete
					title={post.attributes.title}
					sub={post.attributes.sub}
					content={post.attributes.content}
					picture={
						post.attributes.picture?.data.attributes.formats.large?.url ||
						post.attributes.picture?.data.attributes.formats.medium.url
					}
					author={showAuthors(post.attributes.authors, post.attributes.createdBy)}
					publishDate={post.attributes.publishedAt?.split('T')[0]}
					reactions={[post.attributes.hearts, post.attributes.trees, post.attributes.applause]}
					path={`${BLOG_URL}/posts/${post.id}`}
				></PostTemplete>
			)}
		</div>
	);
}

export default PostPage;
